import { useEffect } from "react";
import "./footer.css"

function Footer() {
	useEffect(() => {
		// Créer une balise script
		const script = document.createElement('script')
	
		// Définir l'URL du script externe
		script.src = "https://apis.google.com/js/platform.js"
		script.async = true; // Permet au script de se charger de manière asynchrone
	
		// Ajouter le script dans le document
		document.body.appendChild(script)
	
		// Nettoyer le script lors du démontage du composant
		return () => {
			document.body.removeChild(script)
		};
	}, []);

	return (
		<div className="footer">
			<div className="g-ytsubscribe" data-channelid="UCgyWiTPqbBAui_97Q7VoSCA" data-layout="full" data-count="default"></div>
			<p>
				Créé avec 💖 par Meatman.
			</p>
			<iframe className="discord" src="https://discord.com/widget?id=1154356131494363157&theme=dark" width="350" height="500" allowtransparency="true" frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </div>
	)
}

export default Footer
