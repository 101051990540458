import React, { useContext } from 'react';
import { Link } from 'react-router-dom'
import AuthContext from '../../contexts/authcontext';
import "./header.css"
import Cookies from "js-cookie"
import logo from "../../medias/images/divers/logo-gbh.png"

function Header() {
	const { isConnected, setIsConnected } = useContext(AuthContext);
	const deconnexion = () => {
		Cookies.remove("token")
		setIsConnected(false)
	}
	return (
		<div className="header">
			<nav>
				<Link className='liens animationLien' to={`/events`}>Événements</Link>
				{isConnected && <Link className='liens animationLien' to={`/liste-events`}>Liste Événements</Link>}
				<Link to={`/`}><img src={logo}></img></Link>
				{isConnected && <Link className='liens animationLien' to={`/liste-scenarios`}>Liste Scénarios</Link>}
				{isConnected ?
				<Link onClick={() => {deconnexion()}} className='liens animationLien' to={`/`}>Deconnexion</Link>: 
				<Link className='liens animationLien' to={`/connexion`}>Connexion</Link>}
				
			</nav>
        </div>
	)
}

export default Header
