import { useState, useRef } from "react"
import BoutonDanger from "../../composants/boutons/danger"
import "./image.css"

function InputImage({label, onChange, image, setImage}) {
    const refInput = useRef();

    return (
        <div className="imageInput">
            <label>{ label }</label>
            <input
                type="file"
                name="imageCarte"
                ref={refInput}
                onChange={ onChange }
            />
            {image && (
                <div className="imagePreview">
                    <img alt="not found" src={URL.createObjectURL(image)} />
                    <BoutonDanger texte={"Retirer"} action={() => {
                        setImage(null)
                        refInput.current.value = null
                    }} />
                </div>
            )}
        </div>
    )
}

// Export the UploadAndDisplayImage component as default
export default InputImage