import { useNavigate } from 'react-router-dom'
import axios from "axios"
import { useState, useContext } from 'react'
import Cookies from 'js-cookie';
import "./connexion.css"
import Header from "../../composants/header/header"
import Footer from "../../composants/footer/footer"
import InputTexte from "../../composants/inputs/texte"
import BoutonSimple from "../../composants/boutons/simple"
import AuthContext from '../../contexts/authcontext';
import { Oval } from "react-loader-spinner"

function Connexion() {
	const [identifiant, setIdentifiant] = useState("")
	const [mdp, setMdp] = useState("")
	const [erreur, setErreur] = useState(false)
	const { isConnected, setIsConnected } = useContext(AuthContext);
	const navigate = useNavigate();
	const [chargementConnexion, setChargementConnexion] = useState(false);

	const seConnecter = () => {
		setChargementConnexion(true)
		axios.post("/compte/connexion", {"identifiant": identifiant, "mdp": mdp}).then((res) => {
			Cookies.set("token", res.data["token"])
			setErreur(false)
			setIsConnected(true)
			setChargementConnexion(false)
			navigate('/')
		}).catch((err) => {
			setErreur(true)
			setChargementConnexion(false)
		})
	}

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			seConnecter()
		}
	}

	return (
        <>
			<Header />
			<main>
				<div className='connexion'>
					<div>
						<h1>Connexion</h1>
						<div className='inputs'>
							<InputTexte onKeyDown={handleKeyPress} value={identifiant} onChange={(e) => setIdentifiant(e.target.value)} label={"Identifiant"} />
							<InputTexte type="password" onKeyDown={handleKeyPress} value={mdp} onChange={(e) => setMdp(e.target.value)} label={"Mot de passe"} />
							<BoutonSimple texte={"Valider"} action={() => {seConnecter()}} />
							<Oval visible={chargementConnexion} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
						</div>
						{erreur && <div className='erreur'>Mauvais identifiant ou mot de passe.</div>}
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
}

export default Connexion;
