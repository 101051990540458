import "./date.css"
import { useState, useEffect } from "react";

function Date({value, setValue, label, className}) {

	return (
        <div className={"inputDate " + (className ? className : "")}>
            <label>{ label }</label>
            <input value={value} onChange={(e) => {
                setValue(e.target.value)
            }} type="datetime-local"/>
		</div>
	);
}

export default Date