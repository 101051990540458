import "./simple.css"
import "./danger.css"

function BoutonDanger({ texte, action }) {

	return (
        <>
            <button className="boutonSimple boutonDanger" onClick={action}>{texte}</button>
		</>
	);
}

export default BoutonDanger;
