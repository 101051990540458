import "./scenarioReduit.css"
import enlisted from "../../medias/images/jeux/enlisted.png"
import wot from "../../medias/images/jeux/wot.png"
import wows from "../../medias/images/jeux/wows.png"
import wt from "../../medias/images/jeux/wt.png"
import logoCrayon from "../../medias/images/icones/crayon.png"

function ScenarioReduit({ ouvertureModale, idScenario, titre, texte, jeu}) {
    let img
    switch (jeu) {
        case "enlisted":
            img = enlisted
            break;
        case "wot":
            img = wot
            break;
        case "wows":
            img = wows
            break;
        case "wt":
            img = wt
            break;
        default:
            break;
    }

	return (
        <div className="scenario">
            <div className="gaucheScenario">
                <h3 className="titreScenario">{titre}
                    <img className="iconeCrayon" onClick={() => {ouvertureModale(idScenario, true)}} src={logoCrayon} />
                </h3>
                <p className="texteScenario">{texte}</p>
            </div>
            <div className="droiteScenario">
                <img className="logoJeu" src={img}/>
            </div>
        </div>
	);
}

export default ScenarioReduit;
