import "./404.css"
import { Link } from 'react-router-dom'
import Header from "../../../composants/header/header"
import Footer from "../../../composants/footer/footer"

function Notfound() {
	
	return (
		<>
			<Header />
			<main className="notFound">
                <div>
                    <h1>404 : Page non trouvée</h1>
                    <Link to={"/"}>Revenir à l'accueil</Link>
                </div>
			</main>
			<Footer />
		</>
	);
}

export default Notfound;
