import "./eventReduit.css"
import { Link } from 'react-router-dom'
import enlisted from "../../medias/images/jeux/enlisted.png"
import wot from "../../medias/images/jeux/wot.png"
import wows from "../../medias/images/jeux/wows.png"
import wt from "../../medias/images/jeux/wt.png"
import logoCrayon from "../../medias/images/icones/crayon.png"

function EventReduit({ ouvertureModale, idEvent, titre, texte, lien, date, jeu, modification=false }) {
    let img
    switch (jeu) {
        case "enlisted":
            img = enlisted
            break;
        case "wot":
            img = wot
            break;
        case "wows":
            img = wows
            break;
        case "wt":
            img = wt
            break;
        default:
            break;
    }

    let dateObject = new Date(date)

	return (
        <Link className="lienEvent" to={lien}>
            <div className="event">
                <div className="gaucheEvent">
                    <h3 className="titreEvent">{titre}
                        {/* la modification ça veut dire : ouverture popup modif avec l'id de l'event à aller chercher */}
                        {modification && <img className="iconeCrayon" onClick={(event) => {event.preventDefault(); ouvertureModale(idEvent, true)}} src={logoCrayon} />}
                    </h3>
                    <p className="dateEvent">Commence le {dateObject.toLocaleDateString()} à {dateObject.toLocaleTimeString()}</p>
                    <p className="texteEvent">{texte}</p>
                </div>
                <div className="droiteEvent">
                    <img className="logoJeu" src={img}/>
                </div>
            </div>
        </Link>
	);
}

export default EventReduit;
