import axios from "axios"
import { useState } from 'react'
import "./genToken.css"
import Header from "../../composants/header/header"
import Footer from "../../composants/footer/footer"
import BoutonSimple from "../../composants/boutons/simple"
import BoutonDanger from "../../composants/boutons/danger"
import { Oval } from "react-loader-spinner"

function GenToken() {
    const [lien, setLien] = useState("")
    const [chargementToken, setChargementToken] = useState(false);

	return (
        <>
			<Header />
			<main className="genToken">
				<h1>Générer un token de création de compte</h1>
                <p>
                    {lien}
                    {<Oval visible={chargementToken} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />}
                </p>
                <BoutonSimple action={() => {
                    setChargementToken(true)
                    axios.get("/compte/genToken").then((res) => {
                        setChargementToken(false)
                        setLien(window.location.origin + "/inscription?token=" + res.data["token"])
                    }).catch(() => {
                        setChargementToken(false)
                        setLien("Rip bozo 😂🫵, t'as pas le droit de faire ça !")
                    })
                }} texte={"Générer"} />

                <BoutonDanger action={() => {
                    setChargementToken(true)
                    axios.get("/compte/supprimerTousLesToken").then((res) => {
                        setChargementToken(false)
                        setLien("Ya plus rien !")
                    }).catch(() => {
                        setChargementToken(false)
                        setLien("Rip bozo 😂🫵, t'as pas le droit de faire ça !")
                    })
                }} texte={"Suprimer tous les token"} />
			</main>
			<Footer />
		</>
	);
}

export default GenToken;
