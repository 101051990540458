import "./texte.css"

function InputTexte({value, type="text", onChange, label, onKeyDown, className}) {

	return (
        <div className={"inputTexte " + (className ? className : "")}>
            <label>{ label }</label>
            <input type={type} placeholder={label} onKeyDown={onKeyDown} value={value} onChange={onChange}/>
		</div>
	);
}

export default InputTexte;
