import "./events.css"
import Header from "../../composants/header/header"
import Footer from "../../composants/footer/footer"
import axios from "axios"
import { useState, useEffect } from "react"
import Pagination from "../../composants/pagination/paginations"
import Eventreduit from "../../composants/events/eventReduit"
import { Oval } from "react-loader-spinner"

function Events() {
	const [nbEventsTrouve, setNbEventsTrouve] = useState(0);
	const [listeEvents, setListeEvents] = useState([]);
	const [numeroPageActu, setNumeroPageActu] = useState(1);
	const [maxParPage, setMaxParPage] = useState(3);

	const [chargementEvents, setChargementEvents] = useState(true);

	const getAllProchainsEvent = () => {
		axios.post("/event/getAllProchainsEvent", {numeroPageActu: numeroPageActu-1, maxParPage: maxParPage}).then((res) => {
			setListeEvents(res.data.eventTrouve)
			setNbEventsTrouve(res.data.nbEventsTrouve == 0 ? 1 : res.data.nbEventsTrouve)
			setChargementEvents(false)
		}).catch(() => {setChargementEvents(false)})
	}

	useEffect(() => {
        getAllProchainsEvent()
    }, [numeroPageActu])

	return (
		<>
			<Header />
			<main className="listeEvents">
				<div className="grandEncar">
					<div className="headerEventPublic">
						<h1>Événements à venir</h1>
					</div>
					<div className="contenuEncar events">
						<Pagination messageAucun="Aucun événement à venir" onKeyDown={(event) => {event.key == "Enter" && getAllProchainsEvent(); setNumeroPageActu(1)}} pageMax={Math.ceil(nbEventsTrouve/maxParPage)} maxParPage={maxParPage} setMaxParPage={setMaxParPage} numeroPageActu={numeroPageActu} setNumeroPageActu={setNumeroPageActu}>
							<Oval visible={chargementEvents} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" />
							{listeEvents.length != 0 ?
							listeEvents.map((event) => {
								return <Eventreduit key={event.id} idEvent={event.id} texte={event.event_texte} date={event.date_debut} jeu={event.jeu} lien={"/event/"+event.id} titre={event.titre} />
							})
							:
							!chargementEvents && <h3>Aucun événements à venir.</h3>
							}
						</Pagination>
					</div>
				</div>
			</main>
			<Footer />
		</>
	);
}

export default Events;
