import { Routes, Route, useLocation , Navigate} from 'react-router-dom'
import { useContext } from 'react'
import Accueil from './pages/accueil/accueil'
import Events from './pages/events/events'
import Event from './pages/event/event'
import Connexion from './pages/connexion/connexion'
import Inscription from './pages/inscription/inscription'
import GenToken from './pages/genToken/genToken'
import ListeEvents from './pages/listeEvents/listeEvents'
import ListeScenarios from './pages/listeScenarios/listeScenarios'
import NotFound from './pages/erreurs/404/404'
import AuthContext from './contexts/authcontext';

function App() {
	const location = useLocation()
	const { isConnected, setIsConnected } = useContext(AuthContext);

	return (
		<Routes location={location} key={location.pathname}>
			<Route index element={<Accueil />} />
			<Route path="/connexion" element={<Connexion />} />
			<Route path="/inscription" element={<Inscription />} />
			<Route path="/events" element={<Events />} />
			<Route path="/event/:id" element={<Event />} />
			<Route path="*" element={<NotFound />} />

			{isConnected && 
			<>
			<Route path="/genToken" element={<GenToken />} />
			<Route path="/liste-events" element={<ListeEvents />} />
			<Route path="/liste-scenarios" element={<ListeScenarios />} />
			</>}
        </Routes>
	);
}

export default App;
