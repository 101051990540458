import "./event.css"
import { useParams } from 'react-router-dom'
import Header from "../../composants/header/header"
import Footer from "../../composants/footer/footer"
import { useEffect, useState } from "react";
import axios from "axios";
import enlisted from "../../medias/images/jeux/enlisted.png"
import wot from "../../medias/images/jeux/wot.png"
import wows from "../../medias/images/jeux/wows.png"
import wt from "../../medias/images/jeux/wt.png"
import { Oval } from "react-loader-spinner"

function Event() {
    const { id } = useParams();
	const [idScenario, setIdScenario] = useState(null)
	const [eventTrouve, setEventTrouve] = useState(true)
	const [titre, setTitre] = useState("")
	const [texteScenario, setTexteScenario] = useState("")
	const [texteEvent, setTexteEvent] = useState("")
	const [date, setDate] = useState(new Date().getTime())
	const [nomcarte, setNomCarte] = useState("")
	const [euiqpe1, setequipe1] = useState("")
	const [euiqpe2, setequipe2] = useState("")
	const [jeu, setJeu] = useState("")
	const [duree, setDuree] = useState(0)
	const [regles, setRegles] = useState("")
	const [imageCarte, setImageCarte] = useState(null)

	const [chargementEvent, setChargementEvent] = useState(true);
	const [chargementImage, setChargementImage] = useState(true);

	useEffect(() => {
		axios.post("/event/getEvent", {idEvent: id}).then((res) => {
			setIdScenario(res.data.resultats["id_scenario"])

			setDate(res.data.resultats["date_debut"])
			setTexteEvent(res.data.resultats["event_texte"])
			setTitre(res.data.resultats["titre"])
			setTexteScenario(res.data.resultats["scenario_texte"])
			setNomCarte(res.data.resultats["nom_carte"])
			setequipe1(res.data.resultats["vehicules_1"])
			setequipe2(res.data.resultats["vehicules_2"])
			setRegles(res.data.resultats["regles"])
			setDuree(res.data.resultats["duree"])

			switch (res.data.resultats["jeu"]) {
				case "enlisted":
					setJeu(enlisted)
					break;
				case "wot":
					setJeu(wot)
					break;
				case "wows":
					setJeu(wows)
					break;
				case "wt":
					setJeu(wt)
					break;
				default:
					break;
			}

			setChargementEvent(false)

			axios.get("/images/scenarios/"+res.data.resultats["id_scenario"]+"/carte", { responseType: 'blob' }).then((res) => {
				setImageCarte(res.data)
				setChargementImage(false)
			}).catch((err) => {
				setImageCarte(null)
				setChargementImage(false)
			})
		}).catch(() => {setEventTrouve(false); setChargementEvent(false)})
	}, [id])
    
	return (
		<>
			<Header />
			<main className="pageEvent">
				<div className="conteneur">
					{eventTrouve && !chargementEvent ? <>
					<div className="row">
						<div className="centeredCol">Durée : {duree}h</div>
						<div className="titre"><h2>{titre}</h2></div>
						<div className="centeredCol">Commence :<br/>{new Date(date).toLocaleString()}</div>
					</div>
					{texteEvent &&
					<div className="row">
						<div className="centeredCol">{texteEvent}</div>
					</div>}
					{(texteScenario || nomcarte || imageCarte) &&
					<div className="row">
						<div>
							<div>
								<img className="imageJeu" src={jeu}/>
								{texteScenario}
							</div>
						</div>
						<div className="centeredCol">
							{nomcarte}
							{imageCarte && !chargementImage ? <img className="imageCarte" src={imageCarte && URL.createObjectURL(imageCarte)} /> : chargementImage ? <Oval visible={chargementImage} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" /> : <p>Pas d'image de la carte</p>}
						</div>
					</div>}
					{(euiqpe1 || euiqpe2 ) &&
					<div className="row">
						<div>
							<h3>Véhicules autorisés</h3>
							<div className="row">
								<div className="texteSautLigne">{euiqpe1}</div>
								<span className="ligneVertical"></span>
								<div className="texteSautLigne">{euiqpe2}</div>
							</div>
						</div>
					</div>}
					{regles &&
					<div className="row">
						<div className="texteSautLigne">
							<h3>Règles</h3>
							{regles}
						</div>
					</div>}</> :
					chargementEvent ? <div className="row"><div className="centeredCol"><Oval visible={chargementEvent} height="50" width="50" color="var(--main)" secondaryColor="var(--main-lighter)" ariaLabel="chargement" /></div></div> :
					<div className="row"><div className="centeredCol"><h1>ID d'événement inconnu</h1></div></div>
					}
				</div>
			</main>
			<Footer />
		</>
	);
}

export default Event;
