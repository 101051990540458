import { createContext, useState, useEffect } from 'react'
import axios from 'axios'
import Cookies from "js-cookie"

const AuthContext = createContext()

export const AuthProvider = ({ children }) => {
    const [isConnected, setIsConnected] = useState(false)

    useEffect(() => {
        const token = Cookies.get("token");
        if (token) {
            axios.defaults.headers.common['authorization'] = token
            axios.get("/checkToken").then((res) => {
                setIsConnected(true);
            }).catch((err) => {
                setIsConnected(false);
            })
        } else {
            setIsConnected(false);
        }
    }, [])

    return (
        <AuthContext.Provider value={{ isConnected, setIsConnected }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext
