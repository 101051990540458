import "./select.css"

function Select({options, value, onChange, label}) {

	return (
        <div className={"select"}>
            <label>{ label }</label>
            <select defaultValue={options[0].value} value={value} onChange={onChange}>
                {options.map((elem) => {
                    return <option value={elem.value}>{elem.affichage}</option>
                })}
            </select>
		</div>
	);
}

export default Select