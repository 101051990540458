import "./texteLong.css"

function TexteLong({value, onChange, label, className}) {

	return (
        <div className={"inputTexteLong " + (className ? className : "")}>
            <label>{ label }</label>
            <textarea placeholder={label} value={value} onChange={onChange} type="text"/>
		</div>
	);
}

export default TexteLong