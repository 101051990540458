import "./simple.css"

function BoutonSimple({ texte, action }) {

	return (
        <>
            <button className="boutonSimple" onClick={action}>{texte}</button>
		</>
	);
}

export default BoutonSimple;
