import "./pagination.css"
import { Children } from "react";
import InputTexte from "../../composants/inputs/texte"

function Pagination({children, pageMax, maxParPage, setMaxParPage, numeroPageActu, setNumeroPageActu, onKeyDown, messageAucun="Aucun résultat, essayez une autre recherche"}) {

    const pagePrecedente = () => {
        if (numeroPageActu-1 > 0) {
            setNumeroPageActu(numeroPageActu - 1)
        }
    }

    const pageSuivante = () => {
        if (numeroPageActu+1 <= pageMax) {
            setNumeroPageActu(numeroPageActu + 1)
        }
    }

	return (
        <div className={"pagination"}>
            <div className="contenuChildren">
                {Children.count(children) == 0 ? <h2>{messageAucun}</h2> : Children.map(children, (child) => {
                    return child
                })}
            </div>
            <div className="controlesPagesTout">
                <div className="controlesPages">
                    <div onClick={pagePrecedente} className="conteneurBouton">
                        <i className="boutonControle boutonControleGauche"></i>
                    </div>
                    <div className="numeroPage">
                        {numeroPageActu}/{pageMax}
                    </div>
                    <div onClick={pageSuivante} className="conteneurBouton">
                        <i className="boutonControle boutonControleDroite"></i>
                    </div>
                </div>
                <div className="maxParPage">
                    <label>Nb/page</label>
                    <input onKeyDown={onKeyDown} className="inputMaxParPage" value={maxParPage} type="number" onChange={(event) => {
                        let value = Number(event.target.value)
                        if (value <= 0) {
                            value = 1
                        }
                        event.target.style.width = ((3 + value.toString().length)-1)+"ch"
                        setMaxParPage(value)
                    }} />
                </div>
            </div>
		</div>
	);
}

export default Pagination